/* .slick-slide {
    padding: 0 10px;
  } */
  .slick-list {margin: 0 -8px;}
  .slick-slide>div {padding: 0 8px}
  
  .slick-prev,
.slick-next {
    padding: 8px;
  width: 40px; /* Adjust width */
  height: 40px; /* Adjust height */
  z-index: 10;
  /* background-color: #00796b2e;  */
  border-radius: 10%;
}
.slick-prev:hover,
.slick-next:hover {
  /* background-color: #00796b; Set hover color explicitly */
}


.slick-prev:before,
.slick-next:before {
    
  font-size: 30px; /* Adjust arrow icon size */
  color: #138808; /* Optional: change icon color */
}
